import { AdScriptPlaceEnum } from '@api/api';
import { exitPreview } from '@api/exit-preview';
import { CoinPriceContainer } from '@components/homepage/CoinPrice/CoinPriceContainer/CoinPriceContainer';
import { Navbar } from '@components/layout/Navbar';
import { socials } from '@consts/footer';
import { buildFooterSections } from '@utils/footer';
import { buildCollectionsPageLink } from '@utils/link';
import { buildNavbarLinks } from '@utils/navbar';
import { buildFaviconTags } from '@utils/seo';
import { DefaultSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { FC, useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { NavbarMenuItemProps } from '../Navbar/NavbarMenu/NavbarMenu.types';
import { Background, Container } from './Page.styles';
import { PageProps } from './Page.types';

const CoinPrices = dynamic(
  () => import('@components/homepage/CoinPrice/CoinPrices').then((mod) => mod.CoinPrices),
  { ssr: false },
);
const PreviewHeader = dynamic(
  () => import('@components/article/PreviewHeader').then((mod) => mod.PreviewHeader),
  { ssr: false },
);
const CookiesAlert = dynamic(
  () => import('@components/common/CookiesAlert').then((mod) => mod.CookiesAlert),
  { ssr: false },
);

const Footer = dynamic(() =>
  import('@components/layout/Footer').then<typeof import('@components/layout/Footer').Footer>(
    (mod) => mod.Footer,
  ),
);

export const Page: FC<PageProps> = ({
  children,
  showCoinPriceSlider = false,
  showNewsletter = false,
  preview = false,
  noHeader = false,
  noFooter = false,
  noBackground = false,
  noPadding = false,
  previewSource,
  editPreviewUrl,
  adScripts: adScriptsData,
  globalSettings: globalData,
}) => {
  const { global: globalSettings, footer: footerSettings } = globalData;
  const { sliderAssets } = globalSettings ?? {};
  const { pathname } = useRouter();
  const { t } = useTranslation('common');
  const [loadScripts, setLoadScripts] = useState(false);
  const navbarLinks: NavbarMenuItemProps[] = [
    ...buildNavbarLinks(globalSettings?.navbarCategories),
    {
      name: t('collections'),
      href: buildCollectionsPageLink(),
    },
  ];
  const footerSections = buildFooterSections(footerSettings?.sections);
  const shouldShowCoinPriceSlider = showCoinPriceSlider && Boolean(sliderAssets?.length);

  const onEditPreview = async () => {
    if (previewSource === 'list') {
      await exitPreview();
      window.location.href = editPreviewUrl;
    }
  };

  let headScripts = [];
  let adScripts = [];
  let bodyScripts = [];

  if (adScriptsData) {
    headScripts = adScriptsData.filter((item) => {
      return item.place === AdScriptPlaceEnum.Head && item.src;
    });

    adScripts = adScriptsData.filter((item) => {
      return item.place === AdScriptPlaceEnum.Head && item.script;
    });

    bodyScripts = adScriptsData.filter((item) => {
      return item.place === AdScriptPlaceEnum.Body && item.src;
    });
  }

  useEffect(() => {
    const setFlag = () => {
      setLoadScripts(true);
    };

    window.addEventListener('load', () => {
      setFlag();
    });

    return () => {
      window.removeEventListener('load', setFlag);
    };
  }, []);

  return (
    <>
      {headScripts.map((headScript) => {
        return <Script src={headScript.src} key={headScript.src} strategy="afterInteractive" />;
      })}
      {adScripts.map((adScript, index) => {
        return (
          <Script
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            dangerouslySetInnerHTML={{ __html: adScript.script }}
            id={index.toString()}
            strategy="afterInteractive"
          />
        );
      })}
      <CookiesProvider>
        {loadScripts &&
          bodyScripts.map((bodyScript) => {
            return <Script strategy="lazyOnload" src={bodyScript.src} key={bodyScript.src} />;
          })}
        <Background noBackground={noBackground} />
        <DefaultSeo
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /* @ts-ignore */
          additionalLinkTags={buildFaviconTags(globalSettings?.favicon?.url ?? '')}
          twitter={{ cardType: 'summary_large_image' }}
        />
        {!noHeader && <Navbar pathname={pathname} links={navbarLinks} showSearch />}
        {shouldShowCoinPriceSlider && (
          <CoinPriceContainer>
            <CoinPrices sliderAssets={sliderAssets} />
          </CoinPriceContainer>
        )}
        {preview && (
          <PreviewHeader
            editButtonText="Edit draft"
            showEditButton={previewSource === 'list'}
            onEditClick={onEditPreview}
          />
        )}
        <Container noPadding={noPadding}>{children}</Container>
        {!noFooter && (
          <Footer sections={footerSections} socials={socials} newsletter={showNewsletter} />
        )}
      </CookiesProvider>
    </>
  );
};
