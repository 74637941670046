import { getUrl } from './getUrl';

export const buildLinkData = (name: string, href: string): { name: string; href: string } => ({
  name,
  href,
});

export const buildSearchPageLink = (query: string, tab?: string): string =>
  getUrl(`search?query=${query}&tab=${tab ?? 'all'}`);

export const buildSearchCanonicalPageLink = (): string => getUrl('search');

export const buildArticleLink = (id: string, slug: string): string => getUrl(id, slug);

export const buildCategoryPageLink = (slug: string): string => getUrl(slug);

export const buildSubcategoryPageLink = (categorySlug: string, slug: string): string =>
  getUrl(categorySlug, slug);

export const buildCollectionsPageLink = (): string => getUrl('collections');

export const buildCollectionPageLink = (slug: string): string => getUrl('collections', slug);

export const buildHomepageLink = (): string => getUrl('/');

export const buildInternalLink = (pathname: string): string => getUrl(pathname);

export const buildRSSFeedLink = (): string => getUrl('feed');

export const buildAboutPageLink = (): string => getUrl('about');

export const buildPrivacyPolicyPageLink = (): string => getUrl('privacy-policy');

export const buildContactPageLink = (): string => getUrl('contact');

export const buildTermsOfUsePageLink = (): string => getUrl('terms-of-use');

export const buildAuthorPageLink = (id: string, slug: string): string =>
  getUrl('authors', id, slug);

export const buildLogoPageLink = () => getUrl('crypto-logos');

export const buildTagLink = (slug: string): string => getUrl('tags', slug);

export const buildSingleLogoPageLink = (name: string) =>
  getUrl('crypto-logos', `${name.replace(/\s/g, '-').toLowerCase()}-logo`);
